'use strict';

angular.module('customerPortal').controller('FolderPickerCtrl', ['$state', '$scope', '$sidePanel', '$rootScope', '$dynamicFormService', '$customerFolderService', '$customerUserService', '$customerFormService', '$modalService', '$userStateService', '$userService', function ($state, $scope, $sidePanel, $rootScope, $dynamicFormService, $customerFolderService, $customerUserService, $customerFormService, $modalService, $userStateService, $userService) {

  $scope.$on('form.add', (event, data) => {
    $scope.addFormCallback(data.folder);
  });

  $scope.selectFolderCallback = function (folder) {
    $rootScope.$broadcast('folder.changed', folder.id);
  };

  $scope.selectFormCallback = function (form, folder) {
    $rootScope.$broadcast('form.changed', {
      form: form,
      folder: folder
    });
  };

  $scope.focusFolderCallback = function (folder) {
    $sidePanel.open({
      template: require('../../../templates/portal/folder/folder.settings.html'),
      controller: 'FolderSettingsCtrl',
      resolve: {
        folder: function () {
          return folder;
        }
      }
    }).then(function (result) {
      if (result) {
        $rootScope.$broadcast('folder.' + result.action, result.data);
      }
    });
  };

  $scope.addFolderCallback = function () {
    var definition = require('./forms/AddFolderForm');
    $dynamicFormService.show(definition, {
      resourceParams: [$state.params.customerId],
      resource: $customerFolderService.saveFolder,
      onSuccess: newFolder => {
        $rootScope.$broadcast('folder.added', { folder: newFolder });
      }
    });
  };

  $scope.addFormCallback = function (folder) {
    $modalService.open({
      template: require('../../../templates/portal/form.new.html'),
      controller: 'NewFormCtrl as ctrl',
      size: 'lg',
      windowClass: 'modal-fullscreen',
      resolve: {
        folder: () => folder
      }
    }).then(data => {
      $userStateService.setFormId(data.form.id);
      $rootScope.$broadcast('form.added', { form: data.form, folder: data.folder, formVersion: data.formVersion, beta: data.beta });
    });
  };
}]);
